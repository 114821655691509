'use strict';
export default class SPTracker {
	private config: any;

	/**
	 * Constructs a new SPTracker
	 * @param {Object} config
	 * @param {String} config.environment
	 * @param {String} config.team
	 * @param {String} config.product
	 */
	constructor(config) {
		this.config = config;
	}

	/**
	 * Gets the url
	 * @return {string}
	 * @private
	 */
	static _getUri() {
		return window.location.toString();
	}

	/**
	 * The public API of the tracker
	 * @param {Object} options
	 * @param {Boolean} response - should the promise resolve (do you need the response)
	 * @return {Promise}
	 */
	track(options, response = false) {
		options.url = SPTracker._getUri();
		this.config.tags = this.config.tags || [];
		let tags = this._cleanCombineTwoArrays(options.tags, this.config.tags);
		options = Object.assign(options, this.config);
		if (tags.length > 0) {
			options.tags = tags;
		}
		if (!response) {
			return this._sendRequest(SPTracker._getRequestOptions(options), response);
		} else {
			this._sendRequest(SPTracker._getRequestOptions(options), response);
		}
	}

	/**
	 * Adds constant tag
	 */
	addConstantTag(newTag) {
		if (typeof newTag !== 'string') {
			return;
		}
		let exists = false;
		this.config.tags = this.config.tags || [];
		this.config.tags.map((tag) => {
			if (tag.toLowerCase() === newTag.toLowerCase()) {
				exists = true;
			}
		});
		if (!exists) {
			this.config.tags.push(newTag);
		}
	}

	_cleanCombineTwoArrays(arrayA = [], arrayB = []) {
		let merged = arrayA.concat(arrayB);
		return merged.filter((item, pos) => {
			return merged.indexOf(item) === pos;
		});
	}

	/**
	 * Send the event to the aPI
	 * @param {Object} requestOptions
	 * @param {Boolean} response - should the promise resolve (do you need the response)
	 * @return {Promise}
	 * @private
	 */
	_sendRequest(requestOptions, response = false) {
		return new Promise((resolve, reject) => {
			let request = new XMLHttpRequest();
			if (response) {
				request.onreadystatechange = () => {
					if (request.readyState === XMLHttpRequest.DONE) {
						if (request.status === 200) {
							resolve(JSON.parse(request.responseText));
						} else {
							reject(JSON.parse(request.responseText));
						}
					}
				};
			}
			request.open(requestOptions.method, requestOptions.url);
			request.setRequestHeader('Content-Type', 'application/json');
			request.send(requestOptions.body);
		});
	}

	/**
	 * Configuration for the request
	 * @param {Object} options
	 * @return {{url: string, method: string, body}}
	 * @private
	 */
	static _getRequestOptions(options) {
		return {
			url: 'https://c6coiy9r7a.execute-api.eu-west-1.amazonaws.com/prd/events',
			method: 'PUT',
			body: JSON.stringify(options),
		};
	}
}
